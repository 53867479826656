import React from 'react';
import { Link } from 'react-router-dom';
import MenuList from './MenuList';


const Navbar = () => {
  return (
    <header className="site-navbar py-3" role="banner">
      <div className="container">
        <div className="row align-items-center">

          <div className="col-11 col-xl-2">
            <Link to="/" className="">
                <img
                    src="logo.png"  
                    alt="IST Global"
                    style={{ height: '80px' }}  
                />
            </Link>
          </div>
          <div className="col-12 col-md-10 d-none d-xl-block">
            <nav className="site-navigation position-relative text-right" role="navigation">
              <MenuList className="site-menu js-clone-nav mx-auto d-none d-lg-block" />
            </nav>
          </div>

          <div className="d-inline-block d-xl-none ml-md-0 mr-auto py-3" style={{ position: 'relative', top: '3px' }}>
            <Link to="/" className="site-menu-toggle js-menu-toggle text-white"><span className="icon-menu h3"></span></Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
