import React from 'react';

const FeatureSection = () => {
  return (
    <div className="container">
      <div className="row align-items-center no-gutters align-items-stretch overlap-section">
        <div className="col-md-4">
          <div className="feature-1 pricing h-100 text-center d-flex align-items-center justify-content-center">
            {/* 
            <div className="icon">
              <span className="icon-dollar"></span>
            </div>
            <h2 className="my-4 heading">Best Prices</h2>
            */}
            <p>Belge alım süreci başlar ve kapatılana kadar müşavirlik hizmeti sağlanır. İhracat taahhütleri önceden bildirilir, G.T.İ.P. ve belge bilgileri güncel tutulur. Belge kapatma dosyası hazırlanır ve gümrük teminatları çözülür.</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="free-quote bg-dark h-100">
            <h2 className="my-4 heading text-center">Bültene Abone Olun</h2>
            <form method="post">
              <div className="form-group mb-4">
                <label htmlFor="fq_email">Email</label>
                <input type="text" className="form-control btn-block" id="fq_email" name="fq_email" placeholder="Enter Email" />
              </div>
              <div className="form-group">
                <input type="submit" className="btn btn-primary text-white py-2 px-4 btn-block" value="Gönder" />
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-4">
          <div className="feature-3 pricing h-100 text-center d-flex align-items-center justify-content-center">
            {/* 
            <div className="icon">
              <span className="icon-phone"></span>
            </div>
            <h2 className="my-4 heading">24/7 Support</h2> 
            */}
            <p>İst Global Gümrük Müşavirliği, Yatırım Teşvik Belgesi ile yeni yatırımlar için destek sağlar. Belge kullanımıyla gümrük vergisi, KDV, KKDF, faiz desteği ve gelir vergisi stopajından muafiyet elde edilir. Ayrıca, sigorta primi destekleri kapatma işlemi sonrasında kullanılabilir. Yatırım Teşvik Belgesi süresi genellikle 3 yıldır, ancak yatırımın özelliklerine göre süre artabilir.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
