import React from 'react';
import { GoogleMap } from '@react-google-maps/api';
import {MarkerF} from '@react-google-maps/api'


const GoogleMapPanel = ({ location }) => {
  const mapStyles = {
    height: '400px',
    width: '100%',
  };

  const defaultCenter = {
    lat: location.latitude,
    lng: location.longitude,
  };

  return (
    
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={14}
        center={defaultCenter}
      >
        <MarkerF position={defaultCenter} />
        
      </GoogleMap>
    
  );
};

export default GoogleMapPanel;
