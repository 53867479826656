import React from 'react';

const AddressPanel = () => {
  return (
    <div className="p-4 mb-3 bg-white">
      <p className="mb-0 font-weight-bold">Adres</p>
      <p className="mb-4">
        Dünya Ticaret Merkezi EGS Business, Park Blokları B1-Blok Kat:1 No:19
        34149-Yeşilköy-Bakırköy/İstanbul
      </p>

      <p className="mb-0 font-weight-bold">Telefon</p>
      <p className="mb-4">
        <a href="tel:+902124656781">+90 (0) 212 465 67 81 – 82 – 83</a>
      </p>

      <p className="mb-0 font-weight-bold">Faks</p>
      <p className="mb-4">
        <a href="tel:+902124656784">+90 (0) 212 465 67 84</a>
      </p>

      <p className="mb-0 font-weight-bold">E-posta</p>
      <p className="mb-0">
        <a href="mailto:info@istglobal.com.tr">info@istglobal.com.tr</a>
      </p>
    </div>
  );
};

export default AddressPanel;
