// Home.js
import React from 'react';
import HomePageOverlay from '../components/HomePageOverlay';
import ServicesOverlay from '../components/ServicesOverlay';
import FeatureSection from '../components/FeatureSection';
import AboutSummarySection from '../components/AboutSummarySection';
import AddressPanel from '../components/AddressPanel';
import IstatixSection from '../components/IstatixSection';

const Home = () => {
  return (
    <>
        <HomePageOverlay />
        <FeatureSection />
        <AboutSummarySection />
    
        <ServicesOverlay />

        <IstatixSection />

    </>
  );
};

export default Home;
