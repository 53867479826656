// About.js
import React from 'react';
import { Link } from 'react-router-dom';

import PageOverlay from '../components/PageOverlay';
import ServicesOverlay from '../components/ServicesOverlay';

const About = () => {
  return (
    <>
      <PageOverlay
          backgroundImage="static/images/hero_bg_4.jpg"
          title="Hakkımızda"
        />

      <div className="site-section">
        <div className="container">
          <div className="row mb-5">
            
            <div className="col-md-12 order-md-1" data-aos="fade">
              <div className="text-left pb-1 border-primary mb-4">
                <h3 className="text-primary">İST GLOBAL GÜMRÜK MÜŞAVİRLİĞİ VE LOJİSTİK HİZMETLERİ LTD.ŞTİ.</h3>
              </div>
              
              <p>
              İst Global Gümrük Müşavirliği, geniş yerel ve uluslararası deneyimiyle uzun yıllardır mükelleflerine önemli dış ticaret işlemlerinde nitelikli danışmanlık hizmetleri sunmaktadır. Şirket, bir dizi ofis aracılığıyla çalıştığı tüm firma grupları için yönetici, personel ve sektörel deneyimleri paylaşan uzman danışmanları bünyesinde bulundurmaktadır.
              </p>
              <p>
              İst Global, Gümrük Müşavirliği alanında uzun yıllardan beri dış ticaret şirketlerine ihtiyaç ve talepleri doğrultusunda "Dış Ticaret İşlemleri’nin tüm aşamalarında hizmet vermektedir. Teknolojik alt yapısını güçlendirerek gelişimini hızlandırmış olan İst Global, 2017 yılında İstanbul’da açılmış, zaman içinde Kapıkule ve Çerkezköy’de ofisler açarak şubeleşme yoluna gitmiş ve hizmet alanını genişletmiştir. Şirket, bu hizmet kolunda genişlemeye devam etmektedir.
              </p>
              <p>
              Yaklaşımımız ve başarıya olan inancımızın bir parçası olarak, İst Global müşterilerinin ihtiyaçlarını anlamak ve gerçekleştirmek amacıyla sadece en yetkin ve etkili hizmeti garanti etmektedir. İst Global ekibi, iş ortaklarının iyi niyetini ve güvenini sürdürmek için özen ve gayretle çalışmaktadır. Ayrıca, olağan işlemlerin yanı sıra olağandışı işlemleri de sakin ve pürüzsüz bir şekilde sürdürmek için gerekli tüm kaynaklara, uzmanlığa ve yapıcılığa sahiptir. Bu bağlamda, sizin gibi değerli müşterilere butik hizmet sunmaktan memnuniyet duyarız ve tekrar görüşebilmeyi bekleriz.
              </p>
              <p>
              İst Global Gümrük Müşavirliği, tekstil sektöründe örme kumaş, dokuma kumaş, dokunmamış mensucat, kumaş baskı ve boyama, tekstil kimyasalları ile boyaları ve özellikle tüm konfeksiyon ürünlerinin ithalat, ihracat ve transit işlemleri konusunda yönetim kurulunun yaklaşık 25 yıllık tecrübesi ve uzman kadrosu ile mükelleflerine en iyi hizmeti sunmaktadır. Referanslarımızdan da görüleceği üzere, işlem hacmimizin büyük çoğunluğu konfeksiyon imalatı ve ihracatı ile ilgili gümrük işlemlerini kapsamaktadır. Bu bağlamda; tekstil piyasasının dış ticarette gerektirdiği her türlü iş geliştirme, işlemlerde üst düzey koordinasyon ve bilgilendirme, doğru maliyetlendirme, lojistik destek vb. konularda firmaların tüm ihtiyaçları karşılanmaktadır.
              </p>
              
              <div className="row mt-5">
                <div className="col-md-12 mb-md-5 mb-0 col-lg-6">
                  <div className="unit-4">
                    <div className="unit-4-icon mb-3 mr-4"><span className="text-primary flaticon-frontal-truck"></span></div>
                    <div>
                      <h3>DAHİLDE İŞLEME İZİN BELGESİ UYGULAMALARI</h3>
                      <p>Firma için belge alım sürecinden başlayarak müşavirlik hizmeti sunulur, yerli hammadde alım faturaları otomasyon sistemine kaydedilir, ihracat taahhütleriyle ilgili risk ve avantajlar bildirilir, gümrük işlemleri ve belge kapatma süreci özenle yönetilir.</p>
                      <p className="mb-0"><Link to="/services">Daha Fazla Bilgi</Link></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-md-5 mb-0 col-lg-6">
                  <div className="unit-4">
                    <div className="unit-4-icon mb-3 mr-4"><span className="text-primary flaticon-travel"></span></div>
                    <div>
                      <h3>YATIRIM TEŞVİK BELGESİ UYGULAMALARI</h3>
                      <p>İst Global Gümrük Müşavirliği aracılığıyla Yatırım Teşvik Belgesi alarak, çeşitli yatırım kararları öncesinde 81 ildeki sektörel ve bölgesel destekleri öğrenebilir, belge kullanarak vergi avantajlarından yararlanabilirsiniz.</p>
                      <p className="mb-0"><Link to="/services">Daha Fazla Bilgi</Link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ServicesOverlay />

      <div className="site-section">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12 mb-md-5 mb-0 col-lg-6">
              <div className="unit-4">
                
                <div>
                  <h3>Misyonumuz</h3>
                  <p>İstglobal Gümrük Müşavirliği; Dış Ticaret faaliyetlerinde bulunan firmaların çözüm ortağı olarak, gümrükleme ve lojistik işlemlerinin hızlı, güvenilir ve kaliteli bir şekilde yapılabilmesi için kesintisiz hizmet vermeyi kendine görev edinmiştir.</p>
                  
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-md-5 mb-0 col-lg-6">
              <div className="unit-4">
                
                <div>
                  <h3>Vizyonumuz</h3>
                  <p>Her zaman güvenilir, ilkeli ve sorumlu yaklaşımımızla, değişen dünya ticaretinin gümrükleme ve lojistik alanında tüm ihtiyaçlara cevap verebilecek lider bir firma olmak.</p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default About;
