import React from 'react';

const ServicesOverlay = () => {
  return (
    <div className="site-section bg-image overlay" style={{ backgroundImage: "url('static/images/hero_bg_2.jpg')" }}>
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-md-7 text-center border-primary">
            <h2 className="font-weight-light text-primary" data-aos="fade">Hizmetlerimiz</h2>
          </div>
        </div>
        <div className="row">
          <div className="col mb-5 mb-lg-0 text-center" data-aos="fade" data-aos-delay="100">
            <div className="how-it-work-item">
              
              <div className="how-it-work-body">
                
                <ul className="list-unstyled white">
                  <li className="text-white">Dahilde İşleme Hizmeti</li>
                  <li className="text-white">Yatırım Teşvik Hizmeti</li>
                  <li className="text-white">24 Saat Boyunca Kesintisiz Olarak Geçmiş İşlemler İçin Arşivinize Ulaşma Hizmeti <small>(Firmanıza Tanımladığımız Şifre İle Her An Arşiv Bilgilerinize Ulaşabilirsiniz)</small></li>
                  <li className="text-white">İthalat Hizmeti</li>
                  <li className="text-white">İhracat Hizmeti</li>
                  <li className="text-white">Her Gün Düzenli Olarak Mevzuat Desteği Hizmeti</li>
                  <li className="text-white">Geniş Kurye Ağımız Sayesinde İşlemleri Tamamlanan Evraklarınız Günlük Olarak Firmanıza Ulaştırma Hizmeti</li>

                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ServicesOverlay;
