import React from 'react';

const ContactForm = () => {
  return (
    <form action="#" className="p-5 bg-white">
      <div className="row form-group">
        <div className="col-md-6 mb-3 mb-md-0">
          <label className="text-black" htmlFor="fname">
            İsim
          </label>
          <input type="text" id="fname" className="form-control" />
        </div>
        <div className="col-md-6">
          <label className="text-black" htmlFor="lname">
            Soyisim
          </label>
          <input type="text" id="lname" className="form-control" />
        </div>
      </div>

      <div className="row form-group">
        <div className="col-md-12">
          <label className="text-black" htmlFor="email">
            E-posta
          </label>
          <input type="email" id="email" className="form-control" />
        </div>
      </div>

      <div className="row form-group">
        <div className="col-md-12">
          <label className="text-black" htmlFor="subject">
            Konu
          </label>
          <input type="text" id="subject" className="form-control" />
        </div>
      </div>

      <div className="row form-group">
        <div className="col-md-12">
          <label className="text-black" htmlFor="message">
            Mesaj
          </label>
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="7"
            className="form-control"
            placeholder="Mesajınızı buraya yazabilirsiniz..."
          ></textarea>
        </div>
      </div>

      <div className="row form-group">
        <div className="col-md-12">
          <input
            type="submit"
            value="Gönder"
            className="btn btn-primary py-2 px-4 text-white"
          />
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
