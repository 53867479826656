// Header.js
import React from 'react';
// import { Link } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import Navbar from './Navbar';
import '../assets/styles/common.css';

const Header = () => {
  return (
    <div className="site-wrap">
      <MobileMenu />
      <Navbar />
    </div>
  );
};

export default Header;
