// Contact.js
import React from 'react';
import PageOverlay from '../components/PageOverlay';
import ContactForm from '../components/ContactForm';
import AddressPanel from '../components/AddressPanel';
import BranchesPanel from '../components/BranchesPanel';
import GoogleMapPanel from '../components/GoogleMapPanel';

const Contact = () => {
  const location = {
    latitude: 40.986646,
    longitude: 28.8324,
  };

  return (
    <>
      <PageOverlay
        backgroundImage="static/images/hero_bg_4.jpg"
        title="İletişim"
      />

      <div className="site-section bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mb-5">
              <ContactForm />
            </div>
            <div className="col-md-5">
              <AddressPanel />
              <BranchesPanel />
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <GoogleMapPanel location={location} />
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Contact;
