import React from 'react';
import { Link } from 'react-router-dom';
import MenuList from './MenuList';


const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              
              <div className="col-md-6">
                {/* <h2 className="footer-heading mb-4">Products</h2> */}
                <MenuList className="list-unstyled" />
              </div>
              
              <div className="col-md-6">
                <h2 className="footer-heading mb-4">Faydalı Linkler</h2>
                <ul className="list-unstyled">
                  <li><Link to="https://istatix.istglobal.com.tr/" target="_blank" rel="noopener noreferrer">ISTATIX</Link></li>
                  <li><Link to="https://webgumruk.com/authentication/login.xhtml" target="_blank" rel="noopener noreferrer">Web Gümrük</Link></li>
                </ul>
              </div>
              
            </div>
          </div>
          
          <div className="col-md-4">
            <h2 className="footer-heading mb-4">Bültene Abone Olun</h2>
            <form action="#" method="post">
              <div className="input-group mb-3">
                <input type="text" className="form-control border-secondary text-white bg-transparent" placeholder="E-posta" aria-label="Enter Email" aria-describedby="button-addon2" />
                <div className="input-group-append">
                  <button className="btn btn-primary text-white" type="button" id="button-addon2">Gönder</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row pt-5 mt-5 text-center">
          <div className="col-md-12">
            <div className="border-top pt-5">
            <p>&copy; {new Date().getFullYear()} Tüm hakları saklıdır. | İst Global Gümrük Müşavirliği</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};


export default Footer;
