import React from 'react';

const HomePageOverlay = () => {
  return (
    <div className="site-blocks-cover overlay" style={{ backgroundImage: 'url(static/images/hero_bg_4.jpg)' }} data-aos="fade" data-stellar-background-ratio="0.5">
      <div className="container">
        <div className="row align-items-center justify-content-center text-center">

          <div className="col-md-8" data-aos="fade-up" data-aos-delay="400">
            <h1 className="text-white font-weight-light mb-5 font-weight-bold">Gümrükte Tecrübenin Güvencesi</h1>
            <h2 className="text-white font-weight-bold">İst Global, Dış Ticarette Uzmanlıkta Öncü!</h2>
          </div>

        </div>
      </div>
    </div>
  );
};

export default HomePageOverlay;
