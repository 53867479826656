import React from 'react';
import { Link } from 'react-router-dom';

const PageOverlay = ({ backgroundImage, title }) => {

    const pageTitle = title !== undefined ? title : null;

  return (
    <>
    <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url(${backgroundImage})` }} data-aos="fade" data-stellar-background-ratio="0.5">
      <div className="container">
        <div className="row align-items-center justify-content-center text-center">
            {pageTitle !== null ? (
                <div className="col-md-8" data-aos="fade-up" data-aos-delay="400">
                    <h1 className="text-white font-weight-light text-uppercase font-weight-bold">{pageTitle}</h1>
                    <p className="breadcrumb-custom">
                        <Link to="/">Ana Sayfa</Link> <span className="mx-2">&gt;</span> <span>{pageTitle}</span>
                    </p>
                </div>
            ) : null}

        </div>
      </div>
    </div>

    
</>
    
  );
};

export default PageOverlay;
