import React from 'react';

const IstatixSection = () => {
  return (
    <div className="site-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-5 ml-auto mb-5 order-md-2" data-aos="fade">
            <img src="static/images/istatix-ss.png" alt="Image" className="img-fluid rounded" />
          </div>
          <div className="col-md-6 order-md-1" data-aos="fade">
            <div className="text-left mb-4">
              {/* <h2 className="text-primary"></h2> */}
              <img src="static/images/istatix-logo-02.svg" alt="Istatix Logo" className='img-fluid' style={{height: 80}}></img>
            </div>
            <p>
            İst Gümrük Müşavirliği olarak geliştirdiğimiz ISTATIX, müşterilerimize dış ticaret süreçlerini daha verimli yönetme ve analitik bilgilerle kararlarını destekleme imkanı sunan bir raporlama yazılımıdır. İSTATIX, geçmiş beyannamelerinizi kontrol etme ve detaylı analitik verilere ulaşma konusunda güçlü özelliklere sahiptir.
            </p>
            <p className="mb-5">
            ISTATIX, İst Gümrük Müşavirliği'nin uzmanlığı ve müşteri odaklı yaklaşımını yansıtan bir üründür. Dış ticaretin karmaşıklığına karşı pratik çözümler sunan ISTATIX ile iş süreçlerinizi optimize edin ve başarıya giden yolda bir adım öne geçin.
            </p>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default IstatixSection;
