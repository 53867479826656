// Contact.js
import React from 'react';
import PageOverlay from '../components/PageOverlay';
import ServicesOverlay from '../components/ServicesOverlay';

const Services = () => {
  return (
    <>
      <PageOverlay
        backgroundImage="static/images/hero_bg_4.jpg"
        title="Hizmetler"
      />

      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 ml-auto mb-5 order-md-2" data-aos="fade">
              <img src="static/images/img_4.jpg" alt="Image" className="img-fluid rounded" />
            </div>
            <div className="col-md-7 order-md-1" data-aos="fade">
              <div className="text-left pb-1 border-primary mb-4">
                <h3 className="text-primary">DAHİLDE İŞLEME İZİN BELGESİ UYGULAMALARI</h3>
              </div>
              <p>
              Belge alımı için gerekli prosesler uygulanır. Belge alımını gerçekleştirmeyi müteakip kapatılıncaya kadar firmalara müşavirlik hizmeti verilir, yerli hammadde alım faturalarının otomasyon sistemine kaydı yapılır. Belge süresi bitiminden önce ihracat taahhütlerinizle ilgili tüm riskler ve avantajlar bildirilir. Riskler doğrultusunda, eşyaların serbest dolaşıma giriş rejimine alınması, eşyanın mahrecine iadesi vb. işlemler özenle gerçekleştirilir. G.T.İ.P. madde adı, miktarı ve fiyat değişiklikleri, süre uzatımları ve belgede şirket adres ve unvan değişiklikleri zamanında yapılır. Belge kapatma dosyası hazırlanması, takibi ve kapatma işlemlerinin sonuçlandırılması sağlanır. Belge kapanmasını müteakip gümrüklerdeki teminatlarınızın çözüm işlemleri gerçekleştirilir.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="site-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-4 mr-auto mb-5" data-aos="fade">
              <img src="static/images/img_5.jpg" alt="Image" className="img-fluid rounded" />
            </div>
            <div className="col-md-7 order-md-1" data-aos="fade">
              <div className="text-left pb-1 border-primary mb-4">
                <h3 className="text-primary">YATIRIM TEŞVİK BELGESİ UYGULAMALARI</h3>
              </div>
              <p>
              Yatırım Teşvik Belgesi alarak komple yeni, yenileme, tevsi, modernizasyon vb. yatırım kararları vermeden önce; 81 ilde yeni yatırım teşvik belgesi desteklerine her türlü sektörel ve bölgesel açıdan İst Global Gümrük Müşavirliği aracılığıyla en etkili biçimde ulaşabilirsiniz. Yatırım Teşvik Belgesi desteklerinde nakit para ödemesi yapılmaz. Ancak, Yatırım Teşvik Belgesi kullanarak yatırım yapan yatırımcılar, ödemekle yükümlü oldukları gümrük vergisi, KDV, varsa KKDF, faiz desteği, gelir vergisi stopajını ödemeyerek avantajdan yararlanırlar. Sigorta primi işçi ve işveren hissesi desteklerinde kullanılan Yatırım Teşvik Belgesi kapatma işlemi yapıldıktan sonra yararlanmaya başlarlar. Ayrıca, yatırım yeri tahsisinden de faydalanabilirler. Yatırım Teşvik Belgesi süresi 3 yıldır, ancak yatırımın şekline, büyüklüğüne ve gerçekleşme süresine göre bu süre daha fazla verilebilir.
              </p>
            </div>
          </div>
        </div>
      </div>

      <ServicesOverlay />

      <div className="site-section bg-light">
        <div className="container">
          <h3 className='mb-3'>Gümrük Müşavirliği</h3>
          <div className="row align-items-stretch mb-5">
          
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
                
                <div>
                  <h3>İTHALAT</h3>
                  <ul className='list-unstyled'>
                    <li>Serbest Dolaşıma Giriş</li>
                    <li>Geçici İthalat</li>
                    <li>Dahilde İşleme Rejimi</li>
                    <li>Bedelsiz İthalat</li>
                    <li>Fuat Eşyası</li>
                    <li>ATA Karnesi</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
                
                <div>
                  <h3>İHRACAT</h3>
                  <ul className='list-unstyled'>
                    <li>Geçici İhracat</li>
                    <li>İhracat İşlemleri</li>
                    <li>Mahrece İade</li>
                    <li>Bedelsiz ihracat</li>
                    <li>Dahilde İşleme Rejimi</li>
                    <li>Fuar Eşyası</li>
                    <li>ATA Karnesi</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
                
                <div>
                  <h3>E-Arşiv/Raporlama</h3>
                  <p>Hariçte İşleme İzni Alınması ve Gümrük Uygulamaları</p>
                  
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
                
                <div>
                  <h3>TRANSİT TİCARET</h3>
                  <ul className='list-unstyled'>
                    <li>Transit Ticaret Uygulamaları</li>
                    <li>Transit ve Aktarma (T1 ve TIR Karnesi)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <h3 className='mb-3'>Danışmanlık Hizmetleri</h3>
          <div className="row align-items-stretch mb-5">
          
            <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
                <div>                  
                  <ul className='list-unstyled'>
                    <li>Dış Ticaret Proje Danışmanlığı</li>
                    <li>Yatırım Teşvik Belgesi</li>
                    <li>Dahilde İşleme İzin Belgesi</li>
                    <li>Hariçte İşleme İzin Belgesi</li>
                    <li>Onaylanmış Kişi Statü Belgesi işlemleri ve belge temini</li>
                    <li>Yetkilendirilmiş Yükümlü Sertifikası işlemleri ve belge temini</li>
                    <li>Gümrük Uzlaşmaları</li>
                    <li>İdari Davalarda ve Kararlarda Mevzuat ve Hukuk Danışmanlığı</li>
                    <li>Mevzuat ve Hukuk Danışmanlığı</li>
                  </ul>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
                <div>                  
                  <ul className='list-unstyled'>
                    <li>Güncel Mevzuat Desteği</li>
                    <li>Tarım İl Müdürlüğü İzin Belgeleri</li>
                    <li>Bağlayıcı Tarife Bilgisi Takip ve Sonuçlandırma</li>
                    <li>CE İşareti Uygulamaları</li>
                    <li>TSE Belgelendirme Uygulamaları</li>
                    <li>İşçi Sağlığı Kontrol Belgesi Alımı</li>
                    <li>Kayıt Belgesi Alımı</li>
                    <li>İhracat Ön İzin Belgeleri</li>
                    <li>Teminat çözümleri</li>
                  </ul>

                </div>
              </div>
            </div>
            
          </div>

          <div className="row align-items-stretch mb-5">
          
            <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
                <div>    
                  <h3>Global Lojistik</h3>              
                  <ul className='list-unstyled'>
                    <li>Gümrüklü – Gümrüksüz Depolama ve Antrepo Çözümleri</li>
                    <li>Gümrüklü – Gümrüksüz Eşyanın Nakliye ve Dağıtım Hizmetleri</li>
                    <li>Şehiriçi ve Şehirlerarası Gümrüklü ve Gümrüksüz Nakliye</li>
                    <li>Konteyner Taşıma Hizmetleri</li>
                    <li>Özel Ekipmanlı Taşıma Hizmetleri</li>
                    <li>Profesyonel Taşıma Hizmetleri</li>
                  </ul>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
                <div>
                  <h3>İnsan Kaynakları</h3>                  
                  <ul className='list-unstyled'>
                    <li>İş Sağlığı ve Güvenliği Eğitimi</li>
                    <li>Müşteri Temsilcisi Geliştirme Programı</li>
                    <li>Müşteri Temsilcilerinin Sektörel Bazda Eğitimi</li>
                    <li>Müşteri Temsilcisi ve Yardımcısı Yetiştirme Programı</li>
                  </ul>
                </div>
              </div>
            </div>
            
          </div>

        </div>
      </div>
      
    </>
  );
};

export default Services;
