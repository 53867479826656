import React from 'react';
import BranchList from './BranchList';

const BranchesPanel = () => {
  return (
    <div className="p-4 mb-3 bg-white">
      <h3 className="h5 text-black mb-3">Şubelerimiz</h3>
      <BranchList />
      {/*
      <p>
        <a href="#" className="btn btn-primary px-4 py-2 text-white">
          Learn More
        </a>
      </p>
      */}
    </div>
  );
};

export default BranchesPanel;
