import React from 'react';

const AboutSummarySection = () => {
  return (
    <div className="site-section">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-md-7 text-center border-primary">
            {/* 
            <h2 className="mb-0 text-primary">What We Offer</h2>
            <p className="color-black-opacity-5">Lorem ipsum dolor sit amet.</p>
            */}
          </div>
        </div>
        <div className="row align-items-stretch">
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="unit-4 d-flex">
              <div className="unit-4-icon mr-4"><span className="text-primary flaticon-travel"></span></div>
              <div>
                <h3>Biz Kimiz</h3>
                <p>İst Global Gümrük Müşavirliği, uzun yıllardır geniş yerel ve uluslararası deneyimle dış ticaret işlemlerinde danışmanlık hizmeti sunan bir şirkettir. Tekstil sektöründe özellikle konfeksiyon ürünlerinin ithalat, ihracat ve transit işlemleri konusunda 25 yıllık tecrübesi ve uzman kadrosu ile müşterilere en iyi hizmeti sağlamaktadır.</p>
                {/* <p className="mb-0"><a href="#">Learn More</a></p> */}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="unit-4 d-flex">
              <div className="unit-4-icon mr-4"><span className="text-primary flaticon-sea-ship-with-containers"></span></div>
              <div>
                <h3>Misyonumuz</h3>
                <p>İstglobal Gümrük Müşavirliği; Dış Ticaret faaliyetlerinde bulunan firmaların çözüm ortağı olarak, gümrükleme ve lojistik işlemlerinin hızlı, güvenilir ve kaliteli bir şekilde yapılabilmesi için kesintisiz hizmet vermeyi kendine görev edinmiştir.</p>
                {/* <p className="mb-0"><a href="#">Learn More</a></p> */}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="unit-4 d-flex">
              <div className="unit-4-icon mr-4"><span className="text-primary flaticon-frontal-truck"></span></div>
              <div>
                <h3>Vizyonumuz</h3>
                <p>Her zaman güvenilir, ilkeli ve sorumlu yaklaşımımızla, değişen dünya ticaretinin gümrükleme ve lojistik alanında tüm ihtiyaçlara cevap verebilecek lider bir firma olmak.</p>  
                {/* <p className="mb-0"><a href="#">Learn More</a></p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSummarySection;
