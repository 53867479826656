import React from 'react';
import { Link } from 'react-router-dom';

const MenuList = ({ className }) => {
  return (
    <ul className={`${className}`}>
      <li><Link to="/">Ana Sayfa</Link></li>
      <li><Link to="/about">Hakkımızda</Link></li>
      <li><Link to="/services">Hizmetler</Link></li>
      <li><Link to="https://istatix.istglobal.com.tr/" target='_blank'>İSTATIX</Link></li>
      <li><Link to="/contact">İletişim</Link></li>
    </ul>
  );
};

export default MenuList;
