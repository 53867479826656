import React from 'react';

const BranchList = () => {
  return (
      <ul className='list-unstyled'>
        <li>İSTANBUL</li>
        <li>TEKİRDAĞ</li>
        <li>ÇORLU (AVRUPA SERBEST BÖLGE)</li>
        <li>ÇERKEZKÖY</li>
        <li>DİLOVASI</li>
        <li>DERİNCE</li>
        <li>GEMLİK</li>
      </ul>
  );
};

export default BranchList;
